export default {
    name: 'global',
    store() {
        return {
            isMobileMenuVisible: false,
            isMinicartVisible: false,
            isPredictiveSearchVisible: false,
            isWindowScrolled: false,
            cart: null,
            cart_count: 0,
            popup: false,
            popup_cart: false,
            subnav: '',
            showPasswordReset: false,
            // wishlistItems: [],
            // listId: '',
            // swat: '',
            body: "",
            scrollTopLock: 0,

            init() {
                window.addEventListener('scroll', this.onWindowScrollHandler.bind(this))

                const cartOpen = new URLSearchParams(window.location.search).get('open');

                if (cartOpen == 'true') {
                    this.isMinicartVisible = true;
                }

                if(window.location.href.indexOf("#recover") != -1) {
                    this.showPasswordReset = true;
                }

                this.initLiquidAJaxCart();

                // if (!window.SwymCallbacks) {
                // window.SwymCallbacks = [];
                // }
                // window.SwymCallbacks.push(this.onSwymLoadCallback);

                this.body = document.querySelector("body");
        
                // if( document.querySelector("[data-coming-soon]") != null) {
                //     let comingSoon = document.querySelector("[data-coming-soon]").dataset.comingSoon;
    
                //     setTimeout(() => {
                //     if(document.querySelector(".swym-watchlist-cta") != null) {
                //         let notifyButton = document.querySelector(".swym-watchlist-cta");
                //         notifyButton.innerHTML= `${comingSoon}`;
                //     }
                //     }, "200");
                // }

                
                
                    // const videos = document.querySelectorAll('video');
                    
                    // videos.forEach((vid) => {
                    //     if(vid.parentElement.dataset.videoRender != undefined) {
                    //         let parentEl = vid.parentElement;
                    //         vid.play()
                    //         .then(() => {})
                    //         .catch((error) => {
                    //             vid.remove();
                    //             parentEl.querySelector("[data-img]").classList.remove("hidden");
                    //         });
                    //     }
                    // })

            },

            get bodyClasses() {
                let classes = []

                if (this.isMobileMenuVisible) {
                    classes.push('mobile-menu-visible');
                }

                if (this.isMinicartVisible) {
                    classes.push('cart-visible');
                }

                if (this.popup) {
                    classes.push('popup-visible');
                }

                // if (this.popup_cart) {
                //     classes.push('popup-visible');
                // }

                return classes || ''
            },

            // onSwymLoadCallback(swat) {
            //     // Please make all SWYM-related API calls inside this function.
            //     // You can use the swat argument within this scope.
            //     if (swat) {
            //         window.swat = swat;
            //         window.Alpine.store('global').getWishlist();
            //     }
            // },

            // getWishlist() {
            //     let onSuccess = (lists) => {              
            //         if (lists.length > 0) {
            //             let listConfig = {
            //                 "lid": lists[0].lid, // lid
            //             };

            //             window.Alpine.store('global').listId = lists[0].lid;
                        
            //             // Define success callback
            //             let onSuccess = function(listDetails) {
            //                 // Successfully fetched list details
            //                 if (listDetails.items !== null) {
            //                     window.Alpine.store('global').wishlistItems = listDetails.items;
            //                 }
            //                 window.Alpine.store('global').checkInWishlist();
                            
            //                 console.log("Successfully fetched List details", listDetails);
            //             }
                        
            //             // Define error callback
            //             let onError = function(error) {
            //                 // Error is an xhrObject
            //                 console.log("Error while fetching List details", error);
            //             }
                        
            //             // Call `fetchListDetails` with the above callbacks and listConfig
            //             window.swat.fetchListDetails(listConfig, onSuccess, onError);

            //         } else {
            //             window.Alpine.store('global').makeWishlist();
            //             // window.Alpine.store('global').checkInWishlist();
            //         }
            //     }
            
            //     // Define error callback
            //     let onError = (error) => {
            //         console.log("Error while fetching all Lists", error);
            //     }

            //     window.swat.fetchLists({
            //         callbackFn: onSuccess,
            //         errorFn: onError
            //     });
            // },

            // makeWishlist() {
            //     let listConfig = {
            //     'lname': 'Wishlist'
            //     };
                
            //     // Define success callback
            //     let onSuccess = function(newList) { 
            //         window.Alpine.store('global').getWishlist();
            //     }
                
            //     // Define error callback
            //     let onError = function(error) {
            //         console.log("Error while creating a List", error);
            //     }
                
            //     // Create a new list with the above callbacks and config
            //     window.swat.createList(listConfig, onSuccess, onError);
            // },

            // checkInWishlist() {
            //     const items = document.querySelectorAll('[data-wishlist-item]');
            //     [...items].forEach(item => {
            //         const productId = item.dataset.wishlistItem;
            //         const inWishlist = this.wishlistItems.find(it => {
            //             return it.empi.toString() === productId;
            //         });
    
            //         if (inWishlist) {
            //             item.querySelector('[data-wishlist-add]').classList.add('hidden', 'inactive');
            //             item.querySelector('[data-wishlist-remove]').classList.remove('hidden', 'inactive');
            //         } else {
            //             item.querySelector('[data-wishlist-remove]').classList.add('hidden', 'inactive');
            //             item.querySelector('[data-wishlist-add]').classList.remove('hidden', 'inactive');
            //         }
            //     })
            // },

            // addToWishlist(empi, epi, du){
            //     let product = {
            //         epi: epi,
            //         empi: empi,
            //         du: du,
            //     };

            //     let onSuccess = function (addedListItem){
            //         window.Alpine.store('global').getWishlist();
            //     }

            //     let onError = function (error){
            //         console.log("Error while adding the Product to the List", error);
            //     }

            //     window.swat.addToList(this.listId, product, onSuccess, onError);
            // },

            // removeFromWishlist(empi, epi, du) {
            //     let product = {
            //         epi: epi,
            //         empi: empi,
            //         du: du,
            //     };

            //     let onSuccess = function(deletedProduct) {
            //         window.Alpine.store('global').getWishlist();
            //     }

            //     let onError = function(error) {
            //         console.log("Error while deleting the Product", error);
            //     }

            //     window.swat.deleteFromList(this.listId, product, onSuccess, onError);
            // },

            openMobileMenu() {
                this.isMobileMenuVisible = true
                this.enableBodyScrollLock()
            },

            closeMobileMenu() {
                this.isMobileMenuVisible = false
                this.disableBodyScrollLock()
            },

            toggleMobileMenu() {
                this.isMobileMenuVisible = !this.isMobileMenuVisible

                if(this.isMobileMenuVisible) {
                    this.enableBodyScrollLock()
                } else {
                    this.disableBodyScrollLock()
                }
            },

            initLiquidAJaxCart() {
                document.addEventListener("liquid-ajax-cart:init", (event) => {
                    this.cart = window.liquidAjaxCart.cart;
                });

                document.addEventListener("liquid-ajax-cart:request-end", (event) => {
                    const { requestState, cart, previousCart, sections } = event.detail;

                    if (requestState.requestType === 'add') {
                        if (requestState.responseData?.ok) {
                            this.isMinicartVisible = true;
                            this.enableBodyScrollLock();
                        }
                    }
                    this.cart = cart
                    this.cart_count = this.cart.item_count
                });
            },

            onWindowScrollHandler() {
                const isScrolled = window.scrollY > 0

                this.isWindowScrolled = isScrolled
                document.body.classList[isScrolled ? 'add' : 'remove']('scrolled')
            },


            enableBodyScrollLock() {
                this.scrollTopLock =
                    document.documentElement.scrollTop || document.body.scrollTop;
                this.body.style.overflow = "hidden";
                this.body.style.position = "fixed";
                this.body.style.top = `-${this.scrollTopLock}px`;
                this.body.style.width = "100%";
            },
        
            disableBodyScrollLock() {
                this.body.style.removeProperty("overflow");
                this.body.style.removeProperty("position");
                this.body.style.removeProperty("top");
                this.body.style.removeProperty("width");
                window.scrollTo(0, this.scrollTopLock);
            },

            calculateText() {
                setTimeout(() => { 
                    let texts = document.querySelectorAll('[data-info-text]'); 
                    let maxHeight = 0; 
                    texts.forEach((text) => {
                        text.classList.add("!block"); 
                        let height = text.getBoundingClientRect().height;
                         if (height > maxHeight) { 
                            maxHeight = height; 
                        };
                        text.classList.remove("!block"); 
                    }); 
                    texts.forEach((text) => { 
                        text.style.height = `${maxHeight}px`; 
                        text.classList.remove('opacity-0'); 
                    }); 
                }, 10);
            },

            closeCart() {
            this.isMinicartVisible = false;
            this.disableBodyScrollLock();

            const url = new URL(window.location);
            url.searchParams.set("open", false);
            window.history.pushState({}, "", url);

            }
        }
    }
}